<template>
  <section :class="$style.section">
    <div :class="$style.header">
      <v-avatar progress-color="default" color="primary" size="lg" image-icon="hashtag" />
      <div :class="$style.wrapper">
        <h2 :class="$style.title">{{ $t('postsCount', [countToShow]) }}</h2>
        <p :class="$style.text">{{ $t('browseSeveral') }}</p>
      </div>
    </div>
    <v-tabs
      :tabs="tabs"
      :active-index.sync="tabIndex"
      color="shadow"
      :class="$style.tabs"
      @tab="onChangeTabIndex"
      is-single-tab
    >
      <template #content>
        <v-grid :length="tagPostsLength(tabName)" @infinite="onGetTagPosts" class-name="posts">
          <v-post-preview v-for="post in tagPosts(tabName)" :key="post.id" :post="post" />
          <template #placeholder>
            <p-post-preview v-for="i in 12" :key="i" />
          </template>
        </v-grid>
      </template>
    </v-tabs>
  </section>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

import VAvatar from '@layouts/avatar/VAvatar.vue'
import VTabs from '@layouts/VTabs.vue'
import VGrid from '@layouts/VGrid.vue'
import VPostPreview from '@layouts/postPreview/VPostPreview.vue'
import PPostPreview from '@placeholders/PPostPreview.vue'

import { abbreviateNumber } from '@utils/numbers'

export default Vue.extend({
  name: 'Tag',
  components: { VAvatar, VTabs, VGrid, VPostPreview, PPostPreview },
  data() {
    return {
      tabIndex: 0,
      tabs: [
        { value: this.$t('top'), name: 'top' },
        { value: this.$t('recent'), name: 'recent' },
      ],
    }
  },
  computed: {
    ...mapGetters('Locale', ['locale']),
    ...mapGetters('Tag', ['tag', 'tagPosts', 'tagPostsLength']),
    tagRouteId() {
      return Number(this.$route?.params?.id) ?? null
    },
    tabName() {
      return this.tabs[this.tabIndex].name
    },
    tagName() {
      return this.tag?.name ?? ''
    },
    postsCount() {
      return this.tag?.post_count ?? 0
    },
    countToShow() {
      return abbreviateNumber(this.tag?.post_count ?? 0, this.locale)
    },
  },
  methods: {
    ...mapMutations('Page', ['setPageTitle']),
    ...mapMutations('Tag', ['resetTagPosts']),
    ...mapActions('Tag', ['getTag', 'getTagPosts']),
    setTabIndexByQueryName() {
      const index = this.tabs.findIndex(({ name }) => name === this.$route.query.tab)
      this.tabIndex = index !== -1 ? index : 0
    },
    onChangeTabIndex(name) {
      if (this.$route.query.tab !== name) {
        this.$router.replace({ query: { tab: name } })
      }
    },
    async onGetTagPosts(scroll) {
      await this.getTagPosts({ scroll, tab: this.tabName, tagName: this.tagName })
    },
  },
  watch: {
    '$route.query.tab': {
      handler() {
        this.setTabIndexByQueryName()
      },
    },
  },
  async created() {
    this.setTabIndexByQueryName()
    if (!this.tag) {
      await this.getTag(this.tagRouteId)
    }
    this.setPageTitle({ name: `#${this.tag?.name}` })
  },
  destroyed() {
    this.resetTagPosts()
  },
})
</script>
<style lang="scss" module>
.section {
  display: block;
}

.header {
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 15px;
  column-gap: 15px;
}

.wrapper {
  display: grid;
  align-self: center;
  row-gap: 12px;
}

.title {
  color: $secondary;
  font-weight: 500;
  font-size: 15px;
}

.text {
  color: $secondary-medium;
  font-weight: 400;
  font-size: 12px;
}

.tabs {
  box-shadow: inset 0 1.5px 0 $secondary-light;
}
</style>
