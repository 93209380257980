// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Tag_section_3O5IC{display:block}.Tag_header_3RLj9{position:relative;display:grid;grid-template-columns:auto 1fr;padding:15px;-moz-column-gap:15px;column-gap:15px}.Tag_wrapper_2N39T{display:grid;align-self:center;row-gap:12px}.Tag_title_3aWbf{color:var(--supster-secondary);font-weight:500;font-size:15px}.Tag_text_GfQA6{color:#a09f9f;font-weight:400;font-size:12px}.Tag_tabs_OAYoi{box-shadow:inset 0 1.5px 0 #e5e3e3}", ""]);
// Exports
exports.locals = {
	"section": "Tag_section_3O5IC",
	"header": "Tag_header_3RLj9",
	"wrapper": "Tag_wrapper_2N39T",
	"title": "Tag_title_3aWbf",
	"text": "Tag_text_GfQA6",
	"tabs": "Tag_tabs_OAYoi"
};
module.exports = exports;
